import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Reveal from 'react-reveal/Reveal'
import AboutWidget from "../../../components/Footer/FooterWidget/AboutWidget";
import SolutionWidget from "../../../components/Footer/FooterWidget/SolutionWidget";
import TeamWidget from "../../../components/Footer/FooterWidget/TeamWidget";

function Footer(props) {
    var [fClass] = useState(props.count || 0);
    let FooterData = props.FooterData;
    return(
        <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
            <div className="footer_top">
                <div className="container">
                    <div className="row">
                        {
                            FooterData.CompanyWidget.map(widget=>{
                                return(
                                    <Reveal effect="fadeInUp" key={widget.id}>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                                                <a href="index.html" className="f-logo"><img src={require('../../../assets/static/media/transparent-V2-HQ.4230b85e.png')} width="150" alt=""/></a>
                                                <div className="widget-wrap">
                                                    <div className="contact_info_item">
                                                        <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Office
                                                            Address</h6>
                                                        <p className="f_400 f_size_13">651 N Broad St, Suite 205
                                                            #1875<b> Middletown, Delaware, USA</b> 19709</p>
                                                        <p className="f_400 f_size_13">Independencia 17 Norte, Colonia
                                                            Centro<b> Escuinapa, Sinaloa, Mexico</b> 82400</p>
                                                    </div>
                                                </div>

                                                <div className="widget-wrap">
                                                    <div className="contact_info_item">
                                                        <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contact Info</h6>
                                                        <p className="f_400 f_size_13"><span
                                                            className="f_400 t_color3">Phone:</span> <a
                                                            href="tel:+13023859943">(+1) 302 385 9943</a></p>
                                                        <p className="f_400 f_size_13"><span
                                                            className="f_400 t_color3">Phone:</span> <a
                                                            href="tel:+526691220371">(+52) 669 122 0371</a></p>
                                                        <p className="f_400 f_size_13"><span
                                                            className="f_400 t_color3">Email:</span> <a
                                                            href="mailto:contact@zero-oneit.com">contact@zero-oneit.com</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/*<form action="#" className="f_subscribe mailchimp" method="post">*/}
                                                {/*    <input type="text" name="EMAIL" className="form-control memail" placeholder="Email"/>*/}
                                                {/*    <button className="btn btn-submit" type="submit"><i className="ti-arrow-right"></i></button>*/}
                                                {/*</form>*/}
                                            </div>
                                        </div>
                                    </Reveal>
                                )
                            })
                        }
                        {/*<AboutWidget ftitle="About Us"  FooterData={FooterData}/>*/}
                        {/*<SolutionWidget ftitle="Workflow Solutions" FooterData={FooterData}/>*/}
                        {/*<TeamWidget ftitle="Team Solutions" FooterData={FooterData}/>*/}
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-6">
                            <p className="mb-0 f_400 f_size_15">{FooterData.copywrite}</p>
                        </div>
                        <div className="col-lg-4 col-md-3 col-sm-6">
                            <div className="f_social_icon_two text-center">
                                {
                                    FooterData.socialIcon.map(item =>{
                                        return(
                                            <a href="/" key={item.id}><i className={item.icon}></i></a>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <ul className="list-unstyled f_menu text-right">
                                <li><a href="../../../Pages/Components#">Terms of Use</a></li>
                                <li><a href="../../../Pages/Components#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;