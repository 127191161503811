import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

function Navigation(props) {
    var [mClass, nClass, cClass, slogo, hbtnClass] = useState(props.count || 0 );
    return (
        <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
            <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../../../assets/static/media/transparent-V1-HQ.e2f3e40d.png")} alt="" width="150"/>
                            <img src={require("../../../assets/static/media/transparent-V2-HQ.4230b85e.png")} alt="logo" width="150"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                {/*
                                <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                                    <Link to="./" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Home
                                    </Link>
                                </li>

                                <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Company" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Company</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Company A" className="nav-link" to='/Service'>Company A</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Company B" className="nav-link" to='/ServiceDetails'>Company B</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Services" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services</Link>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <Link to="./" title="Products" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Products</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink exact title="Company A" className="nav-link" to='/Service'>Company A</NavLink></li>
                                        <li className="nav-item"><NavLink exact title="Company B" className="nav-link" to='/ServiceDetails'>Company B</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown submenu nav-item"><Link title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" to="#">Portfolio</Link>
                                    <ul role="menu" className=" dropdown-menu">
                                        <li className="nav-item"><NavLink title="Project A" className="nav-link" to='/Portfolio-2col'>Project A</NavLink></li>
                                        <li className="nav-item"><NavLink title="Project FullWidth" className="nav-link" to='/Portfolio-fullwidth-4col'>Project FullWidth</NavLink></li>
                                    </ul>
                                </li>
    <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/Contact">Contact</NavLink></li> */}
                            </ul>
                            {/*<a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a>*/}
                        </div>
                    </div>
                </nav>
            </header>
        </Sticky>
    );
}

export default Navigation;