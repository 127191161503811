const FooterData = {
    CompanyWidget: [
        {
            id: 1,
            title: "Get In Touch",
            description: "Don’t miss any updates of our new templates and extensions.!",
        }
    ],
    AboutWidget: [
        {
            id: 1,
            title: "Download",
            menuItems: [
                {
                    id: 1,
                    url: '#',
                    text: 'Company',
                },
                {
                    id: 2,
                    url: '#',
                    text: 'Android App',
                },
                {
                    id: 3,
                    url: '#',
                    text: 'ios App',
                },
                {
                    id: 4,
                    url: '#',
                    text: 'Desktop',
                },
                {
                    id: 5,
                    url: '#',
                    text: 'Projects',
                },
                {
                    id: 6,
                    url: '#',
                    text: 'My tasks',
                },
            ],
        },
        {
            id: 2,
            title: "Help",
            menuItems: [
                {
                    id: 1,
                    url: '#',
                    text: 'FAQ',
                },
                {
                    id: 2,
                    url: '#',
                    text: 'Term & conditions',
                },
                {
                    id: 3,
                    url: '#',
                    text: 'Reporting',
                },
                {
                    id: 4,
                    url: '#',
                    text: 'Documentation',
                },
                {
                    id: 5,
                    url: '#',
                    text: 'Support Policy',
                },
                {
                    id: 6,
                    url: '#',
                    text: 'Privacy',
                },
            ],
        },
    ],
    termsCondition: [
        {
            id: 1,
            title: "Team Solutions",
            menuItems: [
                {
                    id: 1,
                    url: '#',
                    text: 'Support',
                },
                {
                    id: 2,
                    url: '#',
                    text: 'Sales',
                },
                {
                    id: 3,
                    url: '#',
                    text: 'Report Abuse',
                },
                {
                    id: 4,
                    url: '#',
                    text: 'System Status',
                }
            ],
        }
    ],
    SocialLinks: [
        {
            id: 1,
            title: "Team Solutions",
            menuItems: [
                {
                    id: 1,
                    url: '#',
                    icon: 'ti-facebook',
                },
                {
                    id: 2,
                    url: '#',
                    icon: 'ti-twitter-alt',
                },
                {
                    id: 3,
                    url: '#',
                    icon: 'ti-vimeo-alt',
                },
                {
                    id: 4,
                    url: '#',
                    icon: 'ti-pinterest',
                }
            ]
        }
    ],
    socialIcon:[
        {
            id: 1,
            url: 'https://www.facebook.com/',
            icon: 'ti-facebook',
        },
        {
            id: 2,
            url: '#',
            icon: 'ti-twitter-alt',
        },
        {
            id: 3,
            url: '#',
            icon: 'ti-github',
        },
        {
            id: 4,
            url: '#',
            icon: 'ti-youtube',
        }
    ],
    copywrite:"© Zero One IT Desarrollos. 2022 All rights reserved.",
    TestimonialText: [
        {
            id: 1,
            image: "new/member_01.jpg",
            description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
            authorName: "Phillip Anthropy",
            authorPost: "UI/UX designer",
        },
        {
            id: 2,
            image: "new/member_01.jpg",
            description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
            authorName: "Phillip Anthropy",
            authorPost: "UI/UX designer",
        },
        {
            id: 3,
            image: "new/member_01.jpg",
            description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
            authorName: "Phillip Anthropy",
            authorPost: "UI/UX designer",
        },
        {
            id: 4,
            image: "new/member_01.jpg",
            description: "He nicked it hanky panky Eaton naff it's your round quaint cheeky cheers, tomfoolery bonnet posh blimey what a plonker vagabond, zonked Elizabeth give us a bell.?",
            authorName: "Phillip Anthropy",
            authorPost: "UI/UX designer",
        }
    ],
    aboutLinks: [
        {
            id: 1,
            url: '#',
            item: 'Developer',
        },
        {
            id: 2,
            url: '#',
            item: 'Blog',
        },
        {
            id: 3,
            url: '#',
            item: 'Investor',
        },
        {
            id: 4,
            url: '#',
            item: 'Sitemap',
        },
        {
            id: 5,
            url: '#',
            item: 'Jobs',
        }
    ],
    helpLinks: [
        {
            id: 1,
            url: '#',
            item: 'Help and Contact',
        },
        {
            id: 2,
            url: '#',
            item: 'Fees',
        },
        {
            id: 3,
            url: '#',
            item: 'Security',
        },
        {
            id: 4,
            url: '#',
            item: 'App',
        },
        {
            id: 5,
            url: '#',
            item: 'Shop',
        }
    ],
    PrivacyLinks: [
        {
            id: 1,
            url: '#',
            item: 'Privacy Policy',
        },
        {
            id: 2,
            url: '#',
            item: 'Legal Agreement',
        },
        {
            id: 3,
            url: '#',
            item: 'Feedback',
        }
    ],
    about: [
        {
            id: 1,
            url: '#',
            text: 'Company',
        },
        {
            id: 2,
            url: '#',
            text: 'Leadership',
        },
        {
            id: 3,
            url: '#',
            text: 'Diversity',
        },
        {
            id: 4,
            url: '#',
            text: 'Jobs',
        },
        {
            id: 5,
            url: '#',
            text: 'Press',
        },
        {
            id: 6,
            url: '#',
            text: 'Wavelength',
        },
    ],
    Solution: [
        {
            id: 1,
            url: '#',
            text: 'Project Management',
        },
        {
            id: 2,
            url: '#',
            text: 'Agile',
        },
        {
            id: 3,
            url: '#',
            text: 'Task Management',
        },
        {
            id: 4,
            url: '#',
            text: 'Reporting',
        },
        {
            id: 5,
            url: '#',
            text: 'Work Tracking',
        },
        {
            id: 6,
            url: '#',
            text: 'See All Uses',
        },
    ],
    teamSolution: [
        {
            id: 1,
            url: '#',
            text: 'Engineering',
        },
        {
            id: 2,
            url: '#',
            text: 'Designers',
        },
        {
            id: 3,
            url: '#',
            text: 'Sales',
        },
        {
            id: 4,
            url: '#',
            text: 'Developers',
        },
        {
            id: 5,
            url: '#',
            text: 'Marketing',
        },
        {
            id: 6,
            url: '#',
            text: 'See All team',
        },
    ],
};
export default FooterData;