import React from "react";
import Navigation from "../commons/components/navigation/Navigation";
import Footer from "../commons/components/footer/Footer";
import FooterData from "../commons/components/footer/FooterData";

function Index() {
  return (
    <div className="body_wrapper">
      <Navigation cClass="custom_container p0" hbtnClass="new_btn" />
      {/*Section is meant to be a place to show  space between navigation and Footer, Can be removed anytime.*/}
      <section className="seo_service_area sec_pad">
        <div className="container flex">
          <div className="text-center">
          <img src={require("../assets/img/5593131.jpg")} width="100%" alt=""/>
            <h2>Hi There. We are working to bring back our website.</h2>
            <p>
              Sorry for the inconvenience...{" "}
            </p>
          </div>
        </div>
      </section>

      <Footer FooterData={FooterData} />
    </div>
  );
}
export default Index;
